.online_banking_sect h1 {
  font-size: 48px;
  color: var(--sec-clr);
  margin-bottom: 10px;
}

.online_banking_sect .home_why_choose_sect_item_one p {
  font-size: 20px;
  font-weight: 500;
  line-height: 2em;
  margin: 30px 0;
}

@media (max-width: 600px) {
  .online_banking_sect h1 {
    font-size: 40px;
    margin-bottom: 0px;
  }
  .online_banking_sect .home_why_choose_sect_item_one p {
    font-size: 16px;
  }
}
