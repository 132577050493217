.mobile_dashboard_navbar {
  display: none;
}

@media (max-width: 600px) {
  .mobile_dashboard_navbar {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px 20px;
    border: 1px solid rgb(207, 219, 224);
    display: flex;
    justify-content: space-between;
    background-color: white;
    z-index: 999;
  }

  .mobile_dashboard_navbar > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
  }

  .mobile_dashboard_navbar p {
    color: #a0aec0;
    font-size: 14px;
  }

  .active_path {
    font-weight: 600;
    color: var(--pri-clr) !important;
  }
}
