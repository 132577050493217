.home_footer_sect {
  margin: 50px 0px;
  background-color: #eef1f3;
  padding: 100px 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  text-align: center;
  grid-template-areas:
    "header header aside"
    "footer footer aside2";
}

.home_footer_sect > div {
  height: 100%;
}

.home_footer_inner_parent {
  display: flex;
  align-items: center;
  transition: all 300ms ease-in-out;
}

.home_footer_text_sect_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 300ms ease-in-out;
}

.home_footer_inner_parent_one {
  grid-area: header;
}
.home_footer_inner_parent_two {
  grid-area: footer;
}

.home_footer_text_sect {
  padding: 20px;
}

.home_footer_inner_parent:hover,
.home_footer_text_sect_wrapper:hover {
  background-color: var(--sec-clr);
  color: white !important;
}

.home_footer_inner_parent:hover .para_text,
.home_footer_text_sect_wrapper:hover .para_text {
  color: white;
}

.home_footer_inner_parent > div {
  width: 50%;
}

.home_footer_sect p,
.why_choose_p_text {
  text-transform: uppercase;
  color: var(--pri-clr);
  font-size: 16px;
  letter-spacing: 1px;
}

.home_footer_sect h2 {
  font-size: 24px;
  font-weight: 700;
  margin: 30px 0;
  line-height: 1.3em;
}

.home_footer_date_text {
  color: #3d4f60;
  font-size: 14px;
  text-align: center;
  text-transform: initial !important;
}

.home_why_choose_sect {
  display: flex;
  justify-content: space-between;
  padding: 50px;
  gap: 50px;
}

.home_why_choose_sect_item_one {
  border-radius: 5px;
  border: 1px var(--pri-clr) solid;
  padding: 50px;
  width: 40%;
}

.home_why_choose_sect_item_two {
  width: 60%;
  padding-left: 100px;
}

.home_why_choose_sect_item_two h1,
.home_service_sect h1,
.home_strategies_sect h1 {
  font-size: 48px;
  color: var(--pri-clr);
  font-weight: 400;
  line-height: 1.3em;
  margin-top: 20px;
  margin-bottom: 50px;
}

.home_why_choose_sect_item_two h1 span,
.home_service_sect h1 span,
.home_strategies_sect h1 span {
  font-weight: 700;
  color: var(--sec-clr);
}

.vision_text {
  color: #999;
  font-size: 18px;
  line-height: 1.9em;
  margin-top: 20px;
  margin-bottom: 30px;
}

.home_why_choose_sect_item_one .vision_text,
.home_sect_hero_sect p {
  color: var(--pri-clr);
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  margin-bottom: 10px;
}

.home_why_choose_sect_item_one h2 {
  font-size: 30px;
  margin-bottom: 20px;
}

.home_why_choose_sect_item_one h3 {
  font-weight: 400;
  font-size: 16px;
  color: var(--pri-clr);
  border-bottom: 1px solid var(--pri-clr);
  padding-bottom: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.home_sect_hero_sect {
  display: flex;
  justify-content: space-between;
  padding: 50px;
  gap: 50px;
}

.home_sect_hero_sect h1 {
  font-size: 48px;
  color: var(--sec-clr);
  margin: 30px 0px;
}

.home_about_image_sect {
  width: 50%;
}
.home_about_text_sect {
  width: 50%;
}

.home_about_sect_last_p {
  line-height: 30px;
}

.home_service_sect {
  padding: 50px;
}

.solutions_sect_wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--pri-clr);
}

.solutions_sect_wrapper > div {
  width: 25%;
  padding: 50px 30px;
  border-right: 1px solid var(--pri-clr);
}

.solutions_sect_wrapper h3 {
  font-weight: 800;
  font-size: 20px;
  line-height: 1.6em;
  margin: 18px 0;
}

.solutions_sect_wrapper p {
  font-size: 18px;
  line-height: 1.6em;
  color: var(--pri-clr);
}

.home_hero_sect {
  background-image: url(../../asset/home.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 700px;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: color-dodge;
  padding: 100px;
  margin-top: 100px;
}

.online_banking_sect .home_hero_sect {
  height: 400px !important;
}

.online_banking_sect .home_hero_sect .btn {
  display: none;
}

.home_hero_sect .btn {
  height: 70px;
  font-size: 18px;
  width: 300px;
  border: none;
  border-radius: 14px;
}

.home_hero_sect p {
  color: white;
  width: 60%;
  font-size: 20px;
  line-height: 1.7em;
  margin-bottom: 30px;
}

.home_hero_sect h1 {
  font-size: 50px;
  color: white;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.home_strategies_sect {
  padding: 50px;
}

.strategies_grid_sect {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
  grid-template-areas:
    "header header1 main main"
    "footer footer main main";
}

.strategies_grid_sect_image_1 {
  grid-area: header;
}
.strategies_grid_sect_image_2 {
  grid-area: header1;
}
.strategies_grid_sect_image_3 {
  grid-area: main;
}
.strategies_grid_sect_image_4 {
  grid-area: footer;
}

@media (max-width: 600px) {
  .home_hero_sect {
    margin-top: 90px;
    padding: 30px 20px;
    height: auto;
  }

  .home_hero_sect h1 {
    font-size: 40px;
  }

  .home_hero_sect p {
    width: 100%;
    font-size: 16px;
  }

  .home_hero_sect .btn {
    height: 55px;
    width: 250px;
    font-size: 16px;
  }
  .home_sect_hero_sect,
  .home_service_sect,
  .home_strategies_sect {
    flex-direction: column;
    padding: 50px 20px;
  }

  .home_service_sect,
  .home_strategies_sect {
    padding-top: 0px;
  }

  .home_about_image_sect,
  .home_about_text_sect,
  .home_why_choose_sect_item_two,
  .home_footer_inner_parent > div {
    width: 100%;
  }

  .home_why_choose_sect_item_two h1,
  .home_service_sect h1,
  .home_strategies_sect h1 {
    font-size: 40px;
  }

  .solutions_sect_wrapper {
    flex-direction: column;
    border: none;
    gap: 20px;
  }

  .solutions_sect_wrapper > div {
    width: 100%;
    padding: 10px 20px;
  }

  .home_strategies_sect {
    padding-top: 0px;
  }

  .strategies_grid_sect {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home_why_choose_sect {
    padding: 30px 20px;
    flex-direction: column;
  }

  .home_why_choose_sect_item_one {
    width: 100%;
    padding: 50px 30px;
  }

  .home_why_choose_sect_item_two {
    padding-left: 0px;
  }

  .vision_text {
    font-size: 16px;
  }

  .home_footer_sect {
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
  }

  .home_footer_sect {
    padding-top: 0px;
  }

  .home_footer_inner_parent {
    flex-direction: column;
  }

  .home_why_choose_sect {
    padding-bottom: 0px;
  }

  .home_footer_text_sect {
    padding: 30px 0;
  }
}
