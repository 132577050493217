.contacts_sect {
  background-color: var(--sec-clr);
  padding: 100px;
}

.contacts_sect {
  color: white;
  display: flex;
  justify-content: space-between;
}

.contacts_sect > div {
  width: 50%;
  gap: 100px;
}

.contacts_sect h1,
.contacts_sect_item_two h1 {
  font-size: 40px;
}

.contacts_sect h1 span {
  font-weight: 300;
}

.contacts_sect p {
  font-size: 18px;
  line-height: 1.9em;
  margin-top: 15px;
}

.contacts_sect_item_one p,
.contacts_sect_item_two p {
  font-size: 19px;
  font-weight: 500;
  letter-spacing: 1.2px;
}

.contacts_sect_item_one p span,
.contacts_sect_item_two p span {
  font-weight: 600;
}

.contacts_sect_item_two p span {
  display: block;
  margin-top: 30px;
}
.contacts_sect_item_two p:nth-of-type(1) span {
  margin-top: 0px !important;
}

.contacts_sect_item_two h3 {
  margin-top: 5px;
  font-weight: 400;
}

.contact_form_header_sect {
  padding: 100px;
  text-align: center;
}

.contact_form_header_sect h3 {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1.1px;
}

.contact_form_header_sect h1 {
  font-size: 48px;
  margin-top: 15px;
  color: var(--sec-clr);
}

.contact_form {
  background-color: white;
  padding: 50px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.contact_form > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.contact_form > div input {
  width: 33.3%;
  outline: none;
  height: 50px;
  border: 1px solid var(--pri-clr);
  padding: 10px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 17px;
  color: var(--pri-clr);
}

.contact_form textarea {
  height: 250px;
  outline: none;
  padding: 10px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
}

.contact_form .btn {
  font-size: 20px;
  width: 30%;
  background-color: var(--pri-clr);
  color: white;
  height: 55px;
  border-radius: 12px;
}

@media (max-width: 600px) {
  .contacts_sect {
    padding: 50px 20px;
    flex-direction: column;
  }
  .contacts_sect > div {
    width: 100%;
  }

  .contacts_sect h1,
  .contacts_sect_item_two h1 {
    font-size: 35px;
  }

  .contacts_sect_item_one p,
  .contacts_sect_item_two p {
    font-size: 16px;
  }
  .contacts_sect_item_two h3 {
    font-size: 15px;
  }

  .contact_form_header_sect {
    padding: 30px 20px;
  }
  .contact_form_header_sect h1 {
    font-size: 40px;
  }

  .contact_form {
    padding: 20px;
  }

  .contact_form .btn {
    width: 100%;
    font-size: 16px;
  }

  .contact_form > div {
    flex-direction: column;
  }
  .contact_form > div input {
    width: 100%;
  }
}
