.login_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px;
  background-image: url(../../asset/home-landing.webp);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.4);
  background-blend-mode: color-dodge;
  height: 100vh;
}

.inner_login_wrapper {
  width: 45%;
  padding: 25px;
  background: #fff;
  text-align: center;
  border-radius: 15px;
  border: 1px solid #e0e6ed;
  -webkit-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.09019607843137255),
    0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.09019607843137255),
    0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.09019607843137255),
    0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06);
}

.inner_login_wrapper h3 {
  font-size: 35px;
  font-weight: 400;
  color: var(--pri-clr);
}

.inner_login_wrapper p,
.register_info_text {
  font-size: 15px;
  font-weight: 600;
  color: #888ea8;
  letter-spacing: 1.1px;
  margin-top: 12px;
}

.login_form {
  text-align: start;
  margin-top: 70px;
}

.login_form p,
.register_form p {
  font-size: 10px;
  font-weight: 700;
  color: #3b3f5c;
  margin-bottom: 8px;
  letter-spacing: 1px;
}

.input_wrapper {
  height: 50px;
  width: 100%;
  border: 1px solid #bfc9d4;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.input_wrapper input {
  outline: none;
  width: 90%;
  height: 100%;
  border: none;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1.1px;
  color: #888ea8;
  background-color: transparent;
}

.create_account_text_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 10px;
}

.forget_password_text_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.create_account_text_wrapper a {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #888ea8;
}
.password {
  width: 85%;
  margin: 0 20px;
}

.login_form .btn {
  width: 100%;
  background-color: var(--pri-clr);
  color: white;
  border: none;
  border-radius: 10px;
  margin-top: 20px;
  height: 50px;
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 10px;
  cursor: pointer;
}

.err {
  display: block;
  color: rgb(205, 46, 46);
  padding-top: 10px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

@media (max-width: 600px) {
  .login_wrapper {
    padding: 20px 15px;
  }
  .inner_login_wrapper {
    width: 100%;
  }
}
