.navbar_wrapper {
  background-color: var(--pri-clr);
  color: white;
  width: 21%;
  padding: 40px 30px;
  /* height: 100vh; */
}

.navbar_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding-bottom: 35px;
}

.navbar_header_sect {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar_header_sect img {
  width: 150px;
}

.navbar_container_top_item {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.nav_tab {
  display: flex;
  align-items: center;
  gap: 35px;
  color: white;
}

.nav_tab svg {
  font-size: 28px;
}

.nav_tab p {
  font-size: 1.1rem;
  font-weight: 500;
}

.activebar {
  padding: 10px 30px;
  width: 130px;
  background-color: white;
  color: var(--pri-clr);
  border-bottom-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  transition: all 350ms ease-in-out;
  text-align: center;
}

@media (max-width: 600px) {
  .navbar_wrapper {
    display: none;
  }
}
