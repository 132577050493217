.register_wrapper {
  text-align: start;
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.active_background_one {
  background-image: url(../../asset/hero-1.jpg) !important;
}
.active_background_two {
  background-image: url(../../asset/personal-loan.jpg) !important;
}

.register_image_sect {
  width: 55%;
}

.register_form_sect {
  background-color: #f2f7f8;
  height: 100%;
  width: 45%;
  padding: 50px;
}

.register_form_sect h1 {
  font-size: 48px;
  color: var(--pri-clr);
  margin-bottom: 20px;
}

.register_form {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.register_form > p {
  margin: 10px 0;
}

.register_form .btn {
  color: white;
  border: none;
  height: 50px;
  font-size: 17px;
  border-radius: 10px;
  margin-top: 35px;
  cursor: pointer;
}

.register_form input {
  height: 100%;
}

.disable_btn {
  background-color: #888ea8 !important;
}

.enable_btn {
  background-color: var(--pri-clr) !important;
}

@media (max-width: 600px) {
  .register_wrapper {
    flex-direction: column;
    padding: 20px;
  }

  .register_image_sect {
    display: none;
  }
  .register_form_sect {
    width: 100%;
    padding: 50px 20px;
  }
}
