.header {
  padding: 60px 30px;
  display: flex;
  align-items: center;
  gap: 30px;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  background-color: var(--pri-clr);
  top: 0;
  height: 100px;
}

.header_nav a {
  font-weight: 600;
  color: white;
  text-transform: capitalize;
  font-size: 18px;
}

.header_nav {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 55%;
}

.header_logo_sect {
  width: 15%;
}

.header .btn {
  border-radius: 12px;
  height: 50px;
  cursor: pointer;
  background-color: white !important;
  border: none;
  color: var(--pri-clr);
  width: 200px;
}

.header_btn_sect {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 30%;
  justify-content: flex-end;
}

.loans_nav_btn {
  position: relative;
  transition: all 300ms ease-in;
  /* overflow: hidden; */
}

.loans_nav {
  transform: translateY(0px) !important;
  transition: all 300ms ease-in;
  top: 50px;
}

.close_loans {
  transform: translateY(-650px);
}

.nav_tabs {
  position: absolute;
  background-color: white;
  border-radius: 14px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: all 300ms ease-in;
  width: 350px;
}

.nav_tabs a {
  color: var(--pri-clr);
}

.header svg {
  display: none;
}

.mobile_btn {
  display: none;
}

.header_logo_sect img {
  width: 200px;
}
@media (max-width: 600px) {
  .header_btn_sect {
    display: none;
  }

  .nav_tabs {
    display: flex;
    position: static;
    width: 100%;
    gap: 30px;
    align-items: center;
    padding: 0;
    position: fixed;
    inset: 0;
    z-index: 9;
    top: 90px;
    border-radius: 0px;
    padding-top: 30px;
  }

  .loans_nav {
    display: flex;
  }

  .close_loans {
    display: none;
  }

  .header_nav {
    width: 100%;
    position: fixed;
    flex-direction: column;
    background-color: white;
    bottom: 0;
    align-items: center;
    height: 100%;
    justify-content: start;
    left: 0;
    top: 90px;
    padding: 130px 20px;
    padding-top: 60px;
    gap: 35px;
    transition: all ease-in-out 300ms;
    overflow-y: scroll;
    text-align: center;
  }

  .header_logo_sect {
    width: auto;
  }

  .header {
    padding: 0 30px;
    justify-content: space-between;
  }

  .header img {
    width: 150px;
  }

  .header svg {
    display: block;
  }
  .header_nav a {
    font-size: 16px;
    font-weight: 700;
    color: var(--pri-clr);
  }

  .mobile_btn {
    display: block;
    width: 95%;
    margin: auto;
  }

  .mobile_btn .btn {
    width: 100%;
    height: 60px;
  }

  .close_nav {
    transform: translateX(400px);
  }
}
