.personal_hero {
  background-image: url(../../../asset/hero-2.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  padding: 100px;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: color-dodge;
}

.personal_wrapper .home_hero_sect {
  height: auto;
  background-image: url(../../../asset/hero-1.jpg);
}

.personal_loan_footer {
  display: flex;
  justify-content: space-between;
  gap: 80px;
  padding: 50px;
}

.online_banking_sect .personal_loan_footer {
  align-items: center;
}

.personal_loan_footer_image_sect {
  width: 65%;
}

.online_banking_sect .personal_loan_footer_image_sect {
  width: 45%;
}

.online_banking_sect .home_why_choose_sect_item_one {
  width: 55% !important;
}

.personal_loan_footer .home_why_choose_sect_item_one {
  width: 35%;
  border: none;
}

.personal_loan_footer .home_why_choose_sect_item_one h3 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.personal_loan_footer_btn_wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.personal_loan_footer_tags {
  margin: 40px 0;
}

.personal_loan_footer_btn_wrapper .btn {
  border: 1px solid var(--pri-clr);
  color: var(--pri-clr);
  background-color: transparent;
  font-size: 15px;
  width: 100%;
}

.personal_loan_footer_btn_wrapper .btn:hover {
  background-color: var(--sec-clr);
  color: white;
  transition: all ease-in 300ms;
  border: none;
  cursor: pointer;
}
.personal_loan_footer_image_sect p {
  font-size: 18px;
  line-height: 1.8em;
  margin: 40px 0;
}

@media (max-width: 600px) {
  .personal_loan_footer {
    flex-direction: column;
    padding: 50px 20px;
    gap: 0px;
  }

  .personal_loan_footer_image_sect,
  .personal_loan_footer .home_why_choose_sect_item_one,
  .online_banking_sect .personal_loan_footer_image_sect,
  .online_banking_sect .home_why_choose_sect_item_one {
    width: 100% !important;
    padding: 20px 0;
  }

  .home_why_choose_sect_item_one {
    padding: 30px 20px;
  }
}
