.invest_wrapper .loan_total_balance_sect {
  gap: 50px;
}

.per_annum_sect {
  width: 35% !important;
  height: 150px !important;
}

.invest_wrapper .loan_total_balance_sect h3 {
  color: #7913e5;
  font-size: 1.875rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.per_annum {
  color: #718096;
  font-size: 0.75rem;
  font-weight: 500;
}

.invest_wrapper .loan_get_started_wrapper > div {
  background-image: url(../../../../asset/investify.png);
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 120px;
}

.invest_info_sect {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.invest_info_sect h2 {
  color: #7913e5;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.invest_info_sect p {
  color: #2d3748;
  font-weight: 500;
  font-size: 17px;
  width: 90%;
  text-align: center;
  margin: auto;
}

.button {
  height: 45px;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: #7913e5;
  border: none;
  color: white;
  padding: 0.75rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 200px;
  text-align: center;
  margin-top: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invest_wrapper .accumulation_sect .button,
.loan_get_started_wrapper .button {
  background-color: white;
  color: var(--pri-clr);
  margin-top: 30px;
}

.invest_wrapper .accumulation_sect .investment_wrapper .button {
  background-color: #7913e5;
  color: white;
}

@media (max-width: 600px) {
  .chart_wrapper {
    border: none !important;
    border-radius: 0px;
    padding: 0 !important;
  }

  .invest_wrapper {
    padding-bottom: 50px;
  }

  .invest_wrapper .accumulation_sect {
    flex-direction: column;
  }

  .invest_wrapper .accumulation_sect {
    width: auto;
  }

  .invest_wrapper .accumulation_sect > div {
    width: 100%;
  }

  .invest_wrapper .accumulation_sect h2 {
    font-size: 1.2rem;
  }

  .invest_wrapper .accumulation_sect .button {
    margin-top: 15px;
  }
}
