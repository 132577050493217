.deposit_modal {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  position: fixed;
  inset: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.deposit_modal_item_one {
  width: 65%;
}
.deposit_modal_item_two {
  width: 35%;
  height: 100%;
  background-color: white;
  padding: 30px;
  overflow-y: scroll;
}

.deposit_modal_item_two h3 {
  font-size: 20px;
}

.deposit_header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.deposit_form {
  margin-top: 50px;
}

.bank_deposit {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 20px;
}

.bank_deposit .history_sect {
  width: 100%;
  display: flex;
  gap: 20px;
}

.bank_deposit h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

.deposit_step_two .btn {
  background-color: var(--pri-clr);
  color: white;
  border: none;
  height: 55px;
  border-radius: 10px;
  margin-top: 15px;
  cursor: pointer;
}

.deposit_form_header .input_wrapper {
  margin-top: 10px;
}

.proof_image {
  width: 100%;
  height: 200px;
  object-fit: contain;
  margin-top: 10px;
}

.bank_deposit > p {
  margin-top: 20px;
  font-weight: 500;
  font-size: 14px;
}

.withdraw_modal_item_two {
  overflow-y: scroll;
}

.account_settings_modal .deposit_modal_item_two .deposit_form h2 {
  color: rgb(13, 96, 216);
}

@media (max-width: 600px) {
  .deposit_modal_item_one {
    display: none;
  }

  .withdraw_modal_item_two {
    padding-bottom: 150px !important;
  }

  .deposit_modal_item_two {
    width: 100%;
    margin: auto;
    padding: 30px 20px;
    padding-bottom: 150px;
    overflow-y: scroll;
  }
  .bank_deposit {
    margin-top: 0px;
  }

  .wallet_history_sect svg {
    display: none;
  }

  .wallet_history_sect p {
    font-size: 0.7rem;
  }
}
