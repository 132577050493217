.footer {
  background-color: var(--sec-clr);
  padding: 100px 50px;
  color: white;
}

.footer_first_sect {
  display: flex;
  justify-content: space-between;
}

.footer h3 {
  margin-bottom: 30px;
  font-size: 25px;
}

.footer a {
  display: block;
  color: white;
  margin-bottom: 20px;
}

.phone_number {
  font-size: 30px !important;
  margin-bottom: 15px;
}

.footer_first_sect p {
  width: 60%;
  font-size: 15px;
  line-height: 30px;
}

.footer_first_sect input {
  background-color: transparent;
  border: 1px solid white;
  height: 50px;
  border-radius: 10px;
  width: 100%;
  margin-top: 15px;
  outline: none;
  color: white;
  font-family: "Plus Jakarta Sans", sans-serif;
  padding: 0 20px;
}

.footer .sign_up {
  margin-top: 15px;
  border: none;
  border-radius: 12px;
  height: 50px;
  width: 50%;
}

.footer img {
  width: 200px;
}

@media (max-width: 600px) {
  .footer {
    flex-direction: column;
    padding: 50px 20px;
  }

  .footer_first_sect {
    flex-direction: column;
    gap: 30px;
  }

  .footer .sign_up {
    width: 100%;
    height: 55px;
  }
}
