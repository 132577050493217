.overview_sect {
  width: 100%;
  /* background-color: black; */
  padding: 30px;
}

.overview_header {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
}

.user_prof {
  background-color: var(--pri-clr);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  text-transform: uppercase;
}

.overview_header_welcome_sect h3 {
  font-size: 1.875rem;
  font-weight: 700;
  text-transform: capitalize;
}

.overview_header_welcome_sect p {
  color: #718096;
  font-weight: 500;
  margin-top: 10px;
}

.accumulation_sect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-top: 50px;
}

.accumulation_sect > div {
  padding: 40px 20px;
  background-image: url(../../../../asset/bg-pattern.1.png);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 33.3%;
  border-radius: 10px;
  background-color: rgb(121, 19, 229);
  color: white;
  display: flex;
  gap: 20px;
  align-items: center;
  font-weight: 700;
  margin-bottom: 10px;
}

.accumulation_sect svg {
  font-size: 30px;
}

.accumulation_sect h2 {
  font-size: 1.5rem;
  margin-top: 10px;
}

.accumulation_sect p {
  opacity: 0.7;
  font-size: 0.875rem;
}

.investment_wrapper {
  background-color: rgb(34, 34, 34) !important;
}

.history_sect {
  border: 1px solid #e2e8f0;
  margin-top: 20px;
  padding: 20px;
  border-bottom-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  margin-bottom: 50px;
}

.history_sect_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.history_sect_item > div img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.history_sect_item > div {
  display: flex;
  gap: 20px;
}

.statement_text {
  color: #4a5568;
  font-size: 0.765rem;
  font-weight: 600;
  margin-bottom: 5px;
}

.date_text {
  color: #718096;
  font-size: 0.75rem;
  font-weight: 600;
}

.overview_history_text {
  margin: 40px 0;
  margin-bottom: 20px;
}

.overview_payment_methods {
  display: flex;
  /* justify-content: space-between; */
  gap: 50px;
}

.overview_payment_methods h3 {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.with_icon_wrapper {
  height: 55px;
  width: 55px;
  border-radius: 10px;
  background-color: var(--pri-clr);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.diffrent {
  text-transform: capitalize;
}

@media (max-width: 600px) {
  .overview_sect {
    background-color: black;
  }
  .overview_payment_methods h3 {
    color: white;
  }
  .overview_header_welcome_sect h3 {
    font-size: 1.2rem;
    color: white;
  }
  .accumulation_sect > div {
    padding: 30px 20px;
  }
  .user_prof {
    width: 40px;
    height: 40px;
    font-size: 10px;
  }

  .button {
    height: 40px;
    font-size: 13px;
  }
  .overview_payment_methods {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .overview_history_text {
    margin-bottom: 0px;
    color: white;
  }

  .overview_history_text:nth-child(2) {
    margin-bottom: 30px;
  }
}
