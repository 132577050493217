.loan_total_balance_sect {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  gap: 20px;
}

.loan_total_balance_sect .history_sect {
  width: 65%;
  margin: 0px;
  padding: 40px 20px !important;
  height: auto;
}

.loan_get_started_wrapper {
  width: 35%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.loan_get_started_wrapper > div {
  background-image: url(../../../../asset/bg-pattern.1.png);
  background-color: rgb(121, 19, 229);
  color: white;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.loan_total_balance_sect h1 {
  font-size: 45px;
  color: #083e9e;
}

.loan_get_started_wrapper h1 {
  font-size: 30px;
  color: white;
}

.loan_wrapper .history_sect .empty_wrapper,
.invest_wrapper .empty_wrapper,
.overview_sect .history_sect .empty_wrapper {
  justify-content: center !important;
}

.loan_wrapper_modal .close_container p {
  font-size: 15px;
}

@media (max-width: 600px) {
  .loan_total_balance_sect .history_sect {
    padding: 20px !important;
  }
  .loan_total_balance_sect h1 {
    font-size: 40px;
  }
  .loan_get_started_wrapper > div h1 {
    font-size: 35px;
  }
}
