.account_tab_sect {
  display: flex;
  justify-content: space-between;
  gap: 100px;
  margin-top: 40px;
}

.account_tab_sect_item_one {
  width: 35%;
}

.account_tab_sect_item_two {
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.account_tab_sect_item_one img {
  margin-bottom: 20px;
}

.account_tab_sect_item_two .user_prof {
  height: 150px;
  width: 150px;
}

.account_tab_sect_item_two .user_prof h2 {
  font-size: 35px;
}

.account_tab_sect_item_one .history_sect {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  cursor: pointer;
}

.account_tab_sect_item_two > h3 {
  font-size: 30px;
  margin-top: 20px;
}

.account_history_sect {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  margin-top: 30px;
}

.account_history_sect .history_sect {
  margin-top: 0px;
}

.account_history_sect h3 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 5px;
}

.account_history_sect p {
  color: #4a5568;
  font-size: 0.875rem;
  font-weight: 500;
}

@media (max-width: 600px) {
  .account_tab_sect {
    flex-direction: column-reverse;
    gap: 50px;
  }
  .account_tab_sect_item_one,
  .account_tab_sect_item_two {
    width: 100%;
  }

  .account_history_sect {
    display: flex;
    flex-direction: column;
  }

  .account_wrapper .user_prof {
    display: none;
  }

  .account_tab_sect_item_two > h3 {
    font-size: 23px;
    margin-top: 0px;
  }
  .account_history_sect h3 {
    font-size: 1rem;
  }

  .account_wrapper {
    padding-bottom: 50px;
  }
}
