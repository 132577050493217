.dasboard {
  display: flex;
  background-color: white;
}

.dashboard_pages {
  padding: 50px;
  width: 79%;
  overflow-y: scroll;
}

@media (max-width: 600px) {
  .dashboard_pages {
    width: 100%;
    padding: 50px 15px;
  }

  .accumulation_sect {
    width: 900px;
  }

  .accumulation_sect_wrapper {
    overflow-x: scroll;
  }

  .loan_total_balance_sect {
    flex-direction: column;
  }
  .loan_total_balance_sect .history_sect,
  .loan_get_started_wrapper {
    width: 100% !important;
  }

  .loan_wrapper {
    padding-bottom: 50px;
  }
}
