@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --pri-clr: #50626a;
  --sec-clr: #7913e5;
}

body {
  font-family: "Plus Jakarta Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f7f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  width: 100%;
  height: auto;
}

select,
input {
  font-size: 16px;
}

a,
li {
  text-decoration: none;
  list-style: none;
}

.Toastify__toast-body > div:last-child {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-weight: 500;
}

.notification_modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  inset: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: "Poppins", sans-serif;
}

.notification_container {
  background-color: white;
  border-radius: 10px;
  padding: 40px;
  width: 500px;
}
.add_food_item_header {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.notification_modal .dashboard_header_text {
  font-size: 18px;
}

.go_back {
  height: 40px;
  width: 40px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.05);
}

.close_container {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 10px;
}

.close_container svg {
  margin-bottom: 30px;
}

.close_container h3 {
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
}

.close_container img {
  height: auto;
  width: 100%;
  margin-bottom: 20px;
}

.close_container p {
  font-size: 16px;
  font-weight: 400;
  color: var(--pri-clr);
  text-align: start;
}

.notification_flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
  margin-top: 20px;
}

.otp_modal .button {
  width: 100%;
  height: 50px;
  font-size: 16px;
  margin-top: 0px;
}

.otp_modal .input_wrapper {
  margin-top: 10px;
}

.tradingview-widget-copyright {
  display: none;
}

.loader_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  font-size: 30px;
  height: 100vh;
}

@media (max-width: 600px) {
  .notification_container {
    width: 90%;
    margin: auto;
    text-align: start;
    padding: 30px;
  }

  .close_container h3 {
    text-align: start;
    line-height: 27px;
    margin-bottom: 15px;
  }
  .notification_flex {
    justify-content: flex-start;
  }
}
